import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/copy.png';
const _hoisted_1 = {
  class: "recharge"
};
const _hoisted_2 = {
  class: "recharge-card"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  style: {
    "color": "#A4A4A4"
  }
};
const _hoisted_5 = {
  class: "recharge-address"
};
const _hoisted_6 = {
  class: "mb-8"
};
const _hoisted_7 = {
  class: "mb-8"
};
const _hoisted_8 = {
  class: "mb-8"
};
import { IMG_URL } from '@/config';
import { router } from '@/router';
import { ref } from 'vue';
import { uploadFile, currencysList, recharge } from '@/http';
import { showToast } from 'vant';
import { useClipboard } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'recharge',
  setup(__props) {
    const {
      t
    } = useI18n();
    const {
      copy,
      isSupported
    } = useClipboard();
    const fileList = ref([]);
    const path = ref('');
    const show = ref(false);
    const actions = ref([]);
    const amount = ref('');
    const hash = ref('');
    const _copy = val => {
      copy(val).then(() => {
        if (!isSupported.value) {
          return showToast(t('current_browser_does_not_support_copy'));
        }
        showToast(t('copy_successful'));
      });
    };
    const back = () => {
      router.back();
    };
    const rechargeData = ref({});
    const rechargeIndex = ref(0);
    const _recharge = () => {
      recharge({
        currency_id: rechargeData.value[rechargeIndex.value].id,
        amount: amount.value,
        hash: hash.value,
        img: path.value
      }).then(({
        msg,
        code
      }) => {
        showToast(msg);
        if (code === 200) {
          router.back();
        }
      });
    };
    const afterRead = file => {
      const formData = new FormData();
      formData.append('file', file.file);
      uploadFile(formData).then(({
        data
      }) => {
        path.value = data;
      });
    };
    const typeSelect = item => {
      rechargeIndex.value = item.value;
      show.value = false;
    };
    const _currencysList = () => {
      currencysList({
        type: 'withdraw'
      }).then(({
        data
      }) => {
        rechargeData.value = data;
        data.map((item, index) => {
          actions.value.push({
            name: `${item.name}-${item.agreement.toUpperCase()}`,
            value: index
          });
        });
      });
    };
    _currencysList();
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_uploader = _resolveComponent("van-uploader");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        "left-arrow": "",
        title: _ctx.$t('deposit'),
        placeholder: "",
        fixed: "",
        onClickLeft: back
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        class: "recharge-form__item",
        onClick: _cache[0] || (_cache[0] = $event => show.value = true)
      }, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('please_select_recharge_currency')), 1), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(rechargeData.value[rechargeIndex.value].name) + "-" + _toDisplayString(rechargeData.value[rechargeIndex.value].agreement.toUpperCase()), 1), _createVNode(_component_van_icon, {
        name: "arrow"
      })])]), _createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
        class: "recharge-card__qr",
        src: _unref(IMG_URL) + rechargeData.value[rechargeIndex.value].img,
        alt: ""
      }, null, 8, _hoisted_3), _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('recharge_address')), 1), _createElementVNode("p", _hoisted_5, [_createTextVNode(_toDisplayString(rechargeData.value[rechargeIndex.value].addr) + " ", 1), _createElementVNode("img", {
        class: "recharge-card__copy",
        src: _imports_0,
        alt: "",
        onClick: _cache[1] || (_cache[1] = $event => _copy(rechargeData.value[rechargeIndex.value].addr))
      })])]), _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('recharge_amount')), 1), _createVNode(_component_van_field, {
        placeholder: _ctx.$t('please_enter_recharge_amount'),
        class: "br-a__8 mb-18",
        modelValue: amount.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => amount.value = $event),
        type: "number"
      }, null, 8, ["placeholder", "modelValue"]), _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('recharge_hash')), 1), _createVNode(_component_van_field, {
        placeholder: _ctx.$t('please_enter_recharge_hash'),
        class: "br-a__8 mb-18",
        modelValue: hash.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => hash.value = $event)
      }, null, 8, ["placeholder", "modelValue"]), _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('upload_recharge_proof_payment_screenshot')), 1), _createVNode(_component_van_uploader, {
        modelValue: fileList.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => fileList.value = $event),
        "after-read": afterRead,
        class: "upload-box",
        "max-count": 1
      }, null, 8, ["modelValue"]), _createVNode(_component_van_button, {
        color: "#FC72FF",
        block: "",
        round: "",
        onClick: _recharge
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('confirm')), 1)]),
        _: 1
      })]), _createVNode(_component_van_action_sheet, {
        show: show.value,
        "onUpdate:show": _cache[5] || (_cache[5] = $event => show.value = $event),
        onSelect: typeSelect,
        actions: actions.value,
        "cancel-text": _ctx.$t('cancel'),
        "close-on-click-action": "",
        onCancel: _cache[6] || (_cache[6] = $event => show.value = false)
      }, null, 8, ["show", "actions", "cancel-text"])], 64);
    };
  }
};